/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  src: url('/assets/fonts/RobotoCondensed-Light.ttf');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  src: url('/assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  src: url('/assets/fonts/RobotoCondensed-Bold.ttf');
}

* {
  font-family: 'Roboto Condensed';
  --ion-font-family: 'Roboto Condensed';
}

// H1: 40Pt, Roboto Condensed
h1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

// H2: 20Pt, Roboto Condensed bold
h2 {
  font-size: 2rem;
  font-weight: 700;
}

// Fließtext: 20 Pt, Roboto Condensed light
p {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

ion-button {
  font-size: 1.5rem;
  width: 200px;
}

ion-alert {
  --min-width: 280px !important;
  .alert-tappable {
    height: unset !important;
    overflow: visible !important;
    contain: initial !important;
  }
  .alert-radio-label {
    white-space: normal !important;
    overflow: visible !important;
  }
}

.sip-padding {
  padding: 32px max(16px, calc((100vw - 1024px) / 2));
}

